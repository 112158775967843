<script setup>
const props = defineProps(["value"]);
const tabsGroup = inject("tabsGroup");

const modelValue = ref({
  tabValue: props.value,
  isTabActive: false,
});

watchEffect(() => {
  modelValue.value.tabValue = props.value;
});

onMounted(() => {
  tabsGroup.register(modelValue);
});

onUnmounted(() => {
  tabsGroup.unregister(modelValue);
});
</script>
<template>
  <div
    class="v-tab-label"
    :class="{ active: modelValue.isTabActive }"
    @click="tabsGroup.activate(value)"
  >
    <slot></slot>
  </div>
</template>
<style scoped>
.v-tab-label {
  animation: appear 700ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
  width: 100%;
  cursor: pointer;
  position: relative;
}

@keyframes appear {
  0% {
    opacity: 0;
    transition: all 150ms ease-in-out;
  }
  100% {
    opacity: 1;
    transition: all 150ms ease-in-out;
  }
}
</style>
